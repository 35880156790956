import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/krakowska-rogatka"

const Investment = ({ data, location }) => {
  const title = "Краківська Рогатка"
  const allApartments = data.allWpApartmentKrakowskaRogatka.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Краківська Рогатка",
        image: withPrefix("/kr-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Кельце", url: "/ua/kvartyry/keltse" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} apartmentList={allApartments} />
      <Nav
        title={title}
        investment="Krakowska Rogatka"
        investmentRaw="krakowska_rogatka"
      />
      <Apartments
        apartmentList={allApartments}
        investment="Krakowska Rogatka"
        investmentRaw="krakowska_rogatka"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <ContactForm
        formType="sale"
        investment="Krakowska Rogatka"
        investmentRaw="krakowska_rogatka"
      />
      {/* <Popup type="kielce" /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentKrakowskaRogatka(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          type
          apartmentNumber
          investmentBuilding
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
